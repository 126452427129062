<template>
  <div>
    <template-table
      :data="surveyResults"
      :column="headers"
      :paging="paging"
      :selectAction="false"
      :tableAction="false"
      :searchAction="false"
    >
      <template v-slot:body="{ item }">
        <td class="cell-overflow">
          <div class="text-overflow">
            {{ item.name }}
          </div>
        </td>
        <td>
          <div class="status" :class="getClassByStatus(item.status)">
            {{ getStatusbyValue(item.status) }}
          </div>
        </td>
        <td class="text-center">
          {{ convertTimestampToDate(item.finishedDate) }}
        </td>
        <td>
          <b-button
            class="btn btn-download ml-0"
            size="sm"
            type="button"
            @click="viewSurveyResult(item.id)"
          >
            <b-icon-eye></b-icon-eye>
          </b-button>
          <!-- v-if="
              (isWriteAllPermission || isWriteOwnerPermission) && item.status
            " -->
          <b-button
            class="btn btn-download ml-4"
            size="sm"
            type="button"
            @click="downloadSurvey(item.id)"
          >
            <b-icon-download></b-icon-download>
          </b-button>
        </td>
      </template>
    </template-table>
    <ModalSurveyResult
      :id="selectedSurvey"
      :patientId="patientId"
      :accountId="this.accountId"
      :ownPackage="this.ownPackage"
      @close="selectedSurvey = null"
    />
  </div>
</template>
<script>
export default {
  name: 'SurveyResult',
  components: {
    ModalSurveyResult: () => import('../components/ModalSurveyResult.vue'),
  },
  data() {
    return {
      ownPackage: null,
      accountId: null,
      surveyResults: [],
      headers: [
        {
          label: 'Khảo sát',
          class: 'text-left',
          key: 'name',
        },
        {
          label: 'Trạng thái',
          class: 'text-left',
          key: 'status',
          style: {
            width: '156px',
            'min-width': '156px',
          },
        },
        {
          label: 'Ngày hoàn thành',
          class: 'text-center',
          key: 'date',
          style: {
            width: '140px',
            'min-width': '140px',
          },
        },
        {
          label: 'Kết quả',
          key: 'result',
          style: {
            width: '140px',
            'min-width': '140px',
          },
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      selectedSurvey: null,
    };
  },
  computed: {
    patientId() {
      return this.$route.params.id;
    },
    searchParams() {
      return {
        patientId: this.patientId,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    getClassByStatus(status) {
      switch (status) {
        case 0:
          return 'not-complete';
        case 1:
          return 'completing';

        default:
          return 'completed';
      }
    },
    getStatusbyValue(status) {
      switch (status) {
        case 0:
          return 'Chưa hoàn thành';
        case 1:
          return 'Đang hoàn thành';

        default:
          return 'Đã hoàn thành';
      }
    },
    async loadData(loading = true) {
      try {
        loading && this.$store.commit('context/setLoading', true);
        const { meta, data, error } = await this.$api.get(
          '/UserDashboard/Survey',
          {
            params: this.searchParams,
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        if (data) {
          this.surveyResults = data.items;
          this.paging.total = data.total;
        }
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
         this.loadPrepareData();
        loading && this.$store.commit('context/setLoading', false);
      }
    },

    async loadPrepareData() {  
        const { meta, data, error } = await this.$api.get(
          '/UserDashboard/GetAccountId',
          {
            params: {
              id: this.patientId,
            },
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        if (data) {
          this.accountId = data.accountId;
          this.ownPackage = data.ownPackage.packageAccountTransactionId;
        }    
    },

    async downloadSurvey(id) {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('/UserDashboard/Survey-Download', {
          params: {
            surveyId: id,
            patientId: this.patientId,
          },
          responseType: 'blob',
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${this.getFileName(res.headers['content-disposition'])}`,
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.showToastrMessage(error.message, 'error');
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
    },
    getFileName(string) {
      if (!string) return;

      return string.split('; ')[1].split('filename=')[1];
    },
    async viewSurveyResult(id) {
      this.selectedSurvey = id;
      await this.delay(50);
      this.$bvModal.show('modal-survey-result');
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.status {
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 6px 12px;

  font-size: 12px;
  line-height: 16px;
  color: #ffffff;

  &.completed {
    background: #008479;
  }

  &.not-complete {
    background: #f58220;
  }

  &.completing {
    background: #21a567;
  }
}

.btn-download::v-deep {
  background: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
